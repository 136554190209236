import { 
    ORDER_PROCESSING,
    NOT_STARTED,
    WIP,
    DONE,
    SUBMITTED,
    RELEASED,
    READY_FOR_CHECK,
    READY_FOR_DRAFTING
 } from "./constants";

export function getSelectedStatusID(eValue) {
    switch (eValue) {
      case 'Order Processing':
        return ORDER_PROCESSING;
      case 'Not started':
        return NOT_STARTED;
      case 'WIP':
        return WIP;
      case 'Done':
        return DONE;
      case 'Submitted':
        return SUBMITTED;
      case 'Released':
        return RELEASED;
      case 'Ready for Check':
        return READY_FOR_CHECK;
      case 'Ready for Drafting':
        return READY_FOR_DRAFTING;
      default:
        return NOT_STARTED;
    }
  }