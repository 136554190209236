import isSupportedTypes from "../Helpers/isSupportedTypes";

export const IsValidStatus = (type, selectedStatus, item) => {
    if (isSupportedTypes(type)) {
      const statusOrder = [
        'Not started',
        'Order Processing',
        'Ready for Drafting',
        'WIP',
        'Ready for Check',
        'Submitted',
        'Released'
      ];
  
      const currentIndex = statusOrder.indexOf(item.esoistatusDesc);
      const selectedIndex = statusOrder.indexOf(selectedStatus);
  
      if (selectedIndex === -1 || (selectedIndex !== currentIndex + 1 && selectedIndex !== currentIndex - 1)) {
        if (selectedStatus === "Order Processing" && currentIndex === -1) {
          return true;
        }
        return false;
      }
      return true;
    }
    return true;
  };